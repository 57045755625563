import React from 'react'
import html from "../../assets/html.png";
import css from "../../assets/css.png";
import javascript from "../../assets/javascript.png";
import reactImage from "../../assets/react.png";
import tailwind from "../../assets/tailwind.png";
import django from "../../assets/django.png";
import mongodb from "../../assets/mongodb.png";
import nodejs from "../../assets/nodejs.png";
import './index.css'

const Skills = () => {
    const techs = [
        {
          id: 1,
          src: html,
          title: "HTML",
          style: "shadow-orange-500",
        },
        {
          id: 2,
          src: css,
          title: "CSS",
          style: "shadow-blue-500",
        },
        {
          id: 3,
          src: javascript,
          title: "JavaScript",
          style: "shadow-yellow-500",
        },
        {
          id: 4,
          src: reactImage,
          title: "React",
          style: "shadow-blue-600",
        },
        {
          id: 5,
          src: tailwind,
          title: "Tailwind",
          style: "shadow-sky-400",
        },
        {
          id: 6,
          src: django,
          title: "Django",
          style: "shadow-green-500",
        },
        {
          id: 7,
          src: mongodb,
          title: "Mongodb",
          style: "shadow-green-400",
        },
        {
          id: 8,
          src: nodejs,
          title: "Nodejs",
          style: "shadow-green-200",
        },
      ];

    return (
        <div id='skills' className='w-full h-screen bg-gradient-to-b from-gray-600 to-gray-600 text-white'>
            <div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white '>
            <div>
          <p className="text-4xl font-bold  text-center">
            Skills
          </p>

        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          {techs.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>


            </div>
        </div>
    )
}

export default Skills
